<template>
<div class="mb-100 pt-40 pt-sp-50">
  <ul class="device mb-40">
    <li class="" :class="{on:isPC}"><a @click="clickDevice('PC')"><img src="../images/ico-pc.svg" alt="PC"></a></li>
    <li class="" :class="{on:isSP}"><a @click="clickDevice('SP')"><img src="../images/ico-sp.svg" alt="SP"></a></li>
  </ul>
  <ul class="list-items" :class="changeDevice()">
    <li class="list-item" v-for="item in itemsFiltered" :key="item.id">
      <a :href="item.url" target="_blank">
        <span class="list-image mb-10">
          <span class="list-image__pc" v-show="isPC" :style="{ backgroundColor: `${item.baseColor.hex}`}">
            <span class="list-image__bg" v-lazy:background-image="item.image_url_pc+'=w1280'"></span>
            <span class="list-image__border" :style="{ borderColor: `${item.mainColor.hex}`}"></span>
          </span>
          <span class="list-image__sp" v-show="isSP" :style="{ backgroundColor: `${item.baseColor.hex}`}">
            <span class="list-image__bg" v-lazy:background-image="item.image_url_sp+'=w750'"></span>
            <span class="list-image__border" :style="{ borderColor: `${item.mainColor.hex}`}"></span>
          </span>
        </span>
      </a>
      <div class="flex flex--js">
        <span class="list-item__title">{{item.name}}</span>
        <div class="list-color">
          <a v-if="item.accentColor" class="list-color__item" :style="{ backgroundColor: `${item.accentColor.hex}` }" @click.prevent="color = color == item.accentColor.hex ? '' : item.accentColor.hex" title="Accent Color"></a>
          <a class="list-color__item" :style="{ backgroundColor: `${item.mainColor.hex}` }" @click.prevent="color = color == item.mainColor.hex ? '' : item.mainColor.hex" title="Main Color"></a>
          <a class="list-color__item" :style="{ backgroundColor: `${item.baseColor.hex}` }" @click.prevent="color = color == item.baseColor.hex ? '' : item.baseColor.hex" title="Base Color"></a>
        </div>
      </div>

      <div v-if="0">
        <div v-for="color in item.colors" class="list-item__title" :style="{ color: `${color.hex}` }" @click.prevent="color = color == color.hex ? '' : color.hex">
          ■ Count:{{color.count}}<br>
          Distance:{{color.distance}}<br>
          H:{{color.h}}<br>
          S:{{color.s}}<br>
          V:{{color.v}}
        </div>
      </div>
    </li>
  </ul>
</div>
</template>

<script>
import EventHub from "../js/EventBus";

const axios = require("axios");
const chroma = require("chroma-js");

module.exports = {

  data: function() {
    return {
      isPC: true,
      isSP: true,
      keyword: '',
      color: '',
      items: []
    };
  },
  created() {
    EventHub.$on('sendkeyword', this.changeKeyword);

    axios.get("/static/data.json").then(response => {
      // console.log(response.data);
      for (const item of response.data.entries) {
        item.baseColor = item.colors[0];

        // ベースカラーとの色差が最も大きい（CIE DE2000）
        // https://www.konicaminolta.jp/instruments/knowledge/color/section2/06.html
        item.mainColor = item.colors.sort((a, b) => {
          return b.distance - a.distance;
        })[0];

        // 彩度と明度の合計が最も高い
        const accentColor = item.colors.sort((a, b) => {
          return (b.s + b.v) - (a.s + a.v);
        })[0];
        if (chroma.distance(accentColor.hex, item.baseColor) > 40 && chroma.distance(accentColor.hex, item.mainColor) > 40) {
          item.accentColor = accentColor;
        }
      }
      this.items = response.data.entries;
      // this.seasonClass = seasonClassMap[response.data.season.four];
      // response.data.season.name = seasonClassMap[response.data.season.four];
      // for (const anime of response.data.animes) {
      //   anime.imageCDNURL = Anime.ImageURL(anime);
      // }
      // for (const service of response.data.services) {
      //   service.imageCDNURL = service.imageURL + "=w290";
      // }
      // for (const broadcaster of response.data.broadcasters) {
      //   broadcaster.imageCDNURL = broadcaster.imageURL + "=w290";
      // }
      // Session.Data.Season = response.data.season;
      // Session.Data.Seasons = response.data.seasons;
      // Session.Data.Animes = response.data.animes;
      // Session.Data.Services = response.data.services;
      // Session.Data.Broadcasters = response.data.broadcasters;
      // this.buildBroadcastersInPrefecture();
      // EventHub.$emit("bootstrap", response.data);
    });

  },
  computed: {
    itemsFiltered: function() {
      const items = this.findBy(this.items, this.keyword, this.color);

      // todo 件数を制限
      // AutoPagerize
      // https://qiita.com/ryo2132/items/a2b2e37d56d468da9e18 とか？
      return items;
    }
  },
  methods: {
    clickDevice: function(_device) {
      if (_device == 'PC') {
        if (this.isPC == true) {
          this.isSP = false;
        } else {
          this.isPC = true;
        }
      }
      if (_device == 'SP') {
        if (this.isSP == true) {
          this.isPC = false;
        } else {
          this.isSP = true;
        }
      }
    },
    changeDevice: function() {
      return {
        'list-items--pcsp': this.isPC && this.isSP,
        'list-items--pc': this.isPC && !this.isSP,
        'list-items--sp': !this.isPC && this.isSP
      }
    },
    findBy: function(list, value, color) {
      const distance = function(item) {
        const cs = item.colors.filter(c => {
          return chroma.distance(color, c.hex) < 40
        }).map(c => {
          return c.count
        }).sort((a, b) => {
          return b - a;
        });
        if (cs.length) {
          return cs[0];
        }
        return 0;
      };
      window.scrollTo(0, 0);
      return list.filter(item => {
        return (
          value === '' ||
          item.name.indexOf(value) >= 0 ||
          item.title.indexOf(value) >= 0 ||
          item.description.indexOf(value) >= 0 ||
          item.url.indexOf(value) >= 0 ||
          item.keywords.indexOf(value) >= 0 ||
          item.tags.indexOf(value) >= 0
        ) && (
          color === '' || distance(item) > 0
        )
      })
      // .sort((a, b) => {
      //   if (!color) {
      //     return 1;
      //   }
      //   return distance(b) - distance(a);
      // })
    },
    changeKeyword(keyword) {
      this.keyword = keyword;
    },
  }

};
</script>

<style lang="scss" scoped>
@import "../sass/resouce.scss";

.list-items {
    display: flex;
    flex-wrap: wrap;
    @include media(sp) {

        @include vw(margin-right,30);
        @include vw(margin-left,30);
    }
}

.list-item {

    margin-bottom: 40px;
    @include media(sp) {
        @include vw(margin-bottom,40);
    }
    &__title {
        @include font(14,20,80,24,24,80);
    }
    a {
        display: block;
        cursor: pointer;
        &:hover {
            .list-image__border {
                opacity: 1;
            }
        }
    }
}

.list-image {
    display: flex;
    justify-content: space-between;
    @include trans(transform , 0.3s, 0s,$easeInOutCubic);
    > span {
        @include trans(box-shadow , 0.3s, 0s,$easeInOutCubic);
        width: 100%;
        display: block;
        position: relative;

        // box-shadow: 0 3px 5px 4px rgba(0,0,0,0.1);
        vertical-align: top;

        &:before {
            content: '';
            display: inline-block;
        }
        img {
            opacity: 0;
        }

    }
    .list-image__bg {
        @include trans(opacity , 0.8s, 0s);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0;
    }

    .list-image__border {
        @include trans(opacity , 0.4s, 0s);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-style: solid;
        border-width: 4px;
        opacity: 0;

    }

    &__pc {
        &:before {
            padding-top: 100% * 575/814;
        }
    }
    &__sp {
        &:before {
            padding-top: 100% * 576/329;
        }
    }

}

.list-image__bg[lazy=loaded] {
    opacity: 1;
    // @include trans(background-image , 0.4s, 0s,$easeInOutCubic);
}

.list-items--pcsp {
    // width: 100%;
    .list-item {
        width: 100%;
    }
    .list-image__pc {
        max-width: 815px;
        margin-right: 16px;
        width: 815 / 1160 * 100%;
        @include media(sp) {
            @include vw(margin-right,16);
        }
    }
    .list-image__sp {
        flex: 1;
        // max-width: 333px;
        // width: 335 / 1160 * 100%;
        &:before {
            // padding-top: 100% * 575/814;
        }
    }
}

.list-items--pc {

    @include media(sp) {
        justify-content: space-between;
    }

    .list-item {
        margin-right: 16px;
        width: calc(100% / 3 - 11px);
        &:nth-child(3n) {
            margin-right: 0;
        }
        @include media(sp) {
            margin-right: 0;
            width: calc(49%);
        }
    }
}

.list-items--sp {

    .list-item {
        margin-right: 16px;
        width: calc(100% / 4 - 12px);
        &:nth-child(4n) {
            margin-right: 0;
        }
        @include media(sp) {

            @include vw(margin-right,16);
            @include vw(width,198);
            &:nth-child(3n) {
                margin-right: 0;
            }
            &:nth-child(4n) {
                @include vw(margin-right,16);
            }
        }
    }
}

.device {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    @include media(sp) {
        @include vw(height,110);
    }
    li {
        height: 32px;
        margin-left: 15px;
        margin-right: 15px;
        opacity: 0.5;

        @include media(sp) {
            @include vw(height,52);
            @include vw(margin-left,24);
            @include vw(margin-right,24);
        }
        &.on {
            opacity: 1;
        }
        img {
            width: auto;
            height: 100%;
        }
    }
}

.list-color {
    display: flex;
    padding-top: 0.4em;
    &__item {
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
        width: 1.2em;
        height: 1.2em;
        margin-left: 1em;
    }
}
</style>
