import "./sass/style.scss";

import Vue from "vue/dist/vue.esm.js";
import VueLazyload from "vue-lazyload";

// Layout ------------------------------
import Side from "./vue/Side.vue";
import Items from "./vue/Items.vue";
import Logo from "./vue/Logo.vue";

Vue.component("side", Side);
Vue.component("items", Items);
Vue.component("logo", Logo);

// Modal ------------------------------
// import YouTubePlayer from "./vue/YouTubePlayer.vue";
// Vue.component("youtube-player", YouTubePlayer);

// App ------------------------------
const axios = require("axios");
const _ = require("lodash");

// Window Event Listeners
// window.addEventListener(
//   "resize",
//   _.debounce(function() {
//     EventHub.$emit("resize", window);
//   }, 300),
//   false
// );

Vue.use(VueLazyload, {
  preLoad: 1.3, // 事前ロードする高さの割合指定
  // error: "images/error.png",  エラー時に表示する画像指定
  // loading: "images/loading.gif",  ロード中に表示する画像指定
  attempt: 1, // ロード失敗した時のリトライの上限指定
});

new Vue({
  el: "#app",
  data() {
    return { sideOpen: false };
  },
  watch: {},
  methods: {
    menu: function() {
      this.sideOpen = !this.sideOpen;
    },
  },
  created() {},
});
