module.exports = function(f, threshold) {
  var timeout;

  return function() {
    var self = this;
    var args = arguments;

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(function() {
      f.apply(self, args);
      timeout = null;
    }, threshold || 100);
  };
};
